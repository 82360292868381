export const topic = {
  americanCinema: {
    entityId: "325833",
    entityUuid: "eb937468-30a9-442b-b923-e15c46c73505",
    name: "American cinema",
    urlAlias: "/topics/american-films",
  },
  architectureAndDesign: {
    entityId: "319933",
    entityUuid: "84dfab0b-6bdd-429d-947c-86266e99c21f",
    name: "Architecture and design",
    urlAlias: "/topics/architecture-and-design",
  },
  art: {
    entityId: "324220",
    entityUuid: "1132e72b-1c93-4c81-88e8-ecf5f81d78b2",
    name: "Art",
    urlAlias: "/topics/art",
  },
  asiaTravel: {
    entityId: "318648",
    entityUuid: "611180dc-72e2-42cd-9997-01a6d0e2a969",
    name: "Asia travel",
    urlAlias: "/topics/asia-travel",
  },
  asianCinema: {
    entityId: "329156",
    entityUuid: "5ddc7a64-5ac8-4285-a354-a7145ae18e1f",
    name: "Asian cinema",
    urlAlias: "/topics/asian-cinema",
  },
  asianCinemaChineseFilms: {
    entityId: "500061",
    entityUuid: "a8547097-04ce-4a3d-aace-9fb173a3c4e5",
    name: "Asian cinema: Chinese films",
    urlAlias: "/topics/asian-cinema-chinese-films",
  },
  asianCinemaHongKongFilms: {
    entityId: "329180",
    entityUuid: "c53d6741-78a4-451d-a439-e73abc83a636",
    name: "Asian cinema: Hong Kong film",
    urlAlias: "/topics/hong-kong-films",
  },
  asianCinemaJapaneseFilms: {
    entityId: "325523",
    entityUuid: "808b8c4c-cd69-41f9-bbe5-75c7ba6f85f1",
    name: "Asian cinema: Japanese films",
    urlAlias: "/topics/asian-cinema-japanese-films",
  },
  asianCinemaKoreanFilms: {
    entityId: "325524",
    entityUuid: "a5979add-1b88-4336-9beb-8aeb9804156b",
    name: "Asian cinema: Korean films",
    urlAlias: "/topics/asian-cinema-korean-films",
  },
  greaterBayAreaBusinessPropertyAndTech: {
    entityId: "513657",
    entityUuid: "d12d86bf-bb9c-4ef0-87db-7aaa1236ff49",
    name: "Greater Bay Area: Business, Property & Tech",
    routeType: "Topic",
    urlAlias: "/topics/greater-bay-area-business-property-tech",
  },
  greaterBayAreaComment: {
    entityId: "513656",
    entityUuid: "b7555083-05e3-4dd9-956f-cbefbd8edd3b",
    name: "Greater Bay Area: Comment",
    routeType: "Topic",
    urlAlias: "/topics/greater-bay-area-comment",
  },
  greaterBayAreaEducationAndMedical: {
    entityId: "513659",
    entityUuid: "1357a1ed-a9c2-408e-a551-6df3a07c4b68",
    name: "Greater Bay Area: Education & Medical",
    routeType: "Topic",
    urlAlias: "/topics/greater-bay-area-education-medical",
  },
  greaterBayAreaNewsAndPolitics: {
    entityId: "513654",
    entityUuid: "fa5900ef-cc10-4eeb-83c6-caeebb0bbafc",
    name: "Greater Bay Area: News & Politics",
    routeType: "Topic",
    urlAlias: "/topics/greater-bay-area-news-politics",
  },
  greaterBayAreaTravelAndLifestyle: {
    entityId: "513658",
    entityUuid: "371711e6-e1de-4532-873c-ee6a5aa75e11",
    name: "Greater Bay Area: Travel & Lifestyle",
    routeType: "Topic",
    urlAlias: "/topics/greater-bay-area-travel-lifestyle",
  },
  hongKongChiefExecutiveElection2022: {
    entityId: "511618",
    entityUuid: "f2025ab2-c284-42a4-83fe-aa21e7ba6054",
    name: "Hong Kong chief executive election 2022",
    routeType: "Topic",
    urlAlias: "/topics/hong-kong-chief-executive-election-2022",
  },
  hongKongCouncilElection2021: {
    entityId: "510368",
    entityUuid: "0aa8899e-5015-4a4e-b71b-375a70190990",
    name: "Hong Kong Legislative Council election 2021",
    routeType: "Topic",
    urlAlias: "/topics/hong-kong-legislative-council-election-2021",
  },
  whatToDoInHongKong: {
    entityId: "516963",
    entityUuid: "157ca9cc-1971-4f1c-a654-04a22b590815",
    name: "What to do in Hong Kong",
    routeType: "Topic",
    urlAlias: "/topics/what-to-do-in-hong-kong",
  },
} as const;
