import styled from "@emotion/styled";
import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";
import { useMeasure } from "react-use";

import type { ContentSchemaRenderProps } from "scmp-app/components/schema-render/content";
import { useImageUtils } from "scmp-app/lib/hooks";
import { normalizeJsxAttribute } from "scmp-app/lib/utils";
import type { imageContent$key } from "scmp-app/queries/__generated__/imageContent.graphql";

import { DefaultImageSize } from "./consts";
import { DynamicStarlingImagePopover } from "./dynamics";
import { ImageStyle } from "./enums";
import { checkContentContainsStarlingImage } from "./starling-image-popover/helpers/checkers";
import { Container, StyledArticleImage } from "./styles";

// TODO: missing swipe widget
export type ExtraProps = {
  enableResize?: boolean;
  lazyload?: boolean;
};

export type Props = {
  className?: string;
  enableResize?: boolean;
  height?: string;
  lazyload?: boolean;
  reference?: imageContent$key;
  src?: string;
  title?: string;
  width?: string;
} & ExtraProps &
  ContentSchemaRenderProps;

const Component: FunctionComponent<Props> = ({
  className,
  enableResize,
  height,
  lazyload = true,
  reference: reference_,
  schemaNode,
  src: source = "",
  title,
  width,
  ...attribs
}) => {
  const content = useFragment(
    graphql`
      fragment imageContent on Content {
        headline
        ...checkersCheckContentContainsStarlingImageContent
      }
    `,
    reference_,
  );

  const isPortrait = Number.parseInt(height ?? "0", 10) > Number.parseInt(width ?? "0", 10);

  let imageStyle = ImageStyle.Full;
  if (isPortrait) imageStyle = ImageStyle.Fixed;

  const [reference, measurements] = useMeasure<HTMLDivElement>();

  const { top } = measurements ?? {};

  const { getResizedImageUrl } = useImageUtils();

  const resizeSource = getResizedImageUrl(source, DefaultImageSize);

  return (
    <Container className={className}>
      {checkContentContainsStarlingImage(content) && (
        <DynamicStarlingImagePopover source={resizeSource} />
      )}
      <StyledArticleImage
        $imageHeight={height}
        $imageStyle={imageStyle}
        $imageWidth={width}
        $relativeHeight={enableResize ? top : undefined}
        {...normalizeJsxAttribute(schemaNode.attribs)}
        {...attribs}
        data-headline={content?.headline}
        imageProps={{ $aspectRatio: "2/1", lazyload, src: resizeSource, title }}
        ref={reference}
        title={title ?? ""}
      />
    </Container>
  );
};
Component.displayName = "Image";
export const Image = styled(Component)``;
