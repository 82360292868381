import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

import { ArticleImage } from "scmp-app/components/article/article-image";
import {
  DescriptionContainer as ArticleImageDescription,
  StyledImage as ArticleImageImg,
} from "scmp-app/components/article/article-image/styles";
import { ImageStyle } from "scmp-app/components/schema-render/common/image/enums";

type ImageProps = {
  $imageHeight?: string;
  $imageStyle?: ImageStyle;
  $imageWidth?: string;
};

type ImageWithRelativeHeightProps = {
  $relativeHeight?: number;
} & ImageProps;

const setWidthByImageProps = (props: ImageProps) => {
  if (!props.$imageStyle) return;
  const styleMap = {
    [ImageStyle.Full]: css`
      inline-size: 100%;
    `,
    [ImageStyle.Fixed]: css`
      inline-size: ${props.$imageWidth}px;
      max-inline-size: 450px;
      margin-inline: auto;
    `,
  };
  return styleMap[props.$imageStyle];
};

export const StyledArticleImage = styled(ArticleImage, {
  ...transientOptions,
})<ImageWithRelativeHeightProps>(
  props => css`
    ${ArticleImageImg} {
      cursor: pointer;

      object-fit: cover;
    }

    ${ArticleImageImg}, ${ArticleImageDescription} {
      ${setWidthByImageProps(props)}
    }
  `,
);

export const Container = styled.div`
  position: relative;
`;
